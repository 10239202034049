import { defineStore } from 'pinia'
import { createInstantBalanceTransferFmpSweepOffer, getAvailableInstantBalanceTransfers } from '@/services/api'
import { logger } from '@/utils/logger'
import { openReplay } from '@/utils/openReplay'
import { InstantBalanceTransferOption } from 'aven_types/dist/my/instantBalanceTransfer'

export const usePersonalFinancialLiabilityStore = () => {
    const personalFinancialLiabilityStore = defineStore('personalFinancialLiabilityStore', {
        state: () => {
            return {
                instantBalanceTransfer: {
                    instantBalanceTransferOptions: [],
                    instantBalanceTransferFmpSweepOptions: [],
                },
            }
        },
        getters: {
            instantBalanceTransferOptions: (state) => state.instantBalanceTransfer.instantBalanceTransferOptions,
            instantBalanceTransferFmpSweepOptions: (state) => state.instantBalanceTransfer.instantBalanceTransferFmpSweepOptions,
        },
        actions: {
            updateInstantBalanceTransferOption(updatedOption: InstantBalanceTransferOption) {
                logger.info(`Updating instant balance transfer option w/ personal financial liability ID ${updatedOption.methodfiAccountId} to: ${JSON.stringify(updatedOption)}`)
                this.instantBalanceTransfer.instantBalanceTransferOptions = this.instantBalanceTransfer.instantBalanceTransferOptions.map((option) => {
                    if (updatedOption.methodfiAccountId === option.methodfiAccountId) {
                        return updatedOption
                    }
                    return option
                })
            },
            async getInstantBalanceTransferOptions() {
                try {
                    logger.info(`Getting instant balance transfer options`)
                    const response = await getAvailableInstantBalanceTransfers()
                    if (!response.data.success) {
                        logger.error(`getAvailableInstantBalanceTransfers failed with ${response.data.error}`)
                        return
                    }
                    this.instantBalanceTransfer.instantBalanceTransferOptions = response.data.payload
                    logger.info(`Got instant balance transfer options ${JSON.stringify(this.instantBalanceTransfer.instantBalanceTransferOptions)}`)
                } catch (e) {
                    logger.error(`Failed to get instant balance transfer options: ${e.message}`)
                    this.instantBalanceTransfer.instantBalanceTransferOptions = []
                }
            },
            getInstantBalanceTransferOption(methodfiAccountId: string) {
                return this.instantBalanceTransfer.instantBalanceTransferOptions.find((option) => {
                    return option.methodfiAccountId === methodfiAccountId
                })
            },
            async loadInstantBalanceTransferFmpSweepOffer() {
                this.instantBalanceTransfer.instantBalanceTransferFmpSweepOptions = []
                try {
                    logger.info(`Loading instant BT FMP sweep offer`)
                    const response = await createInstantBalanceTransferFmpSweepOffer()
                    if (!response.data.success || !Array.isArray(response.data.payload)) {
                        logger.info(`createInstantBalanceTransferFmpSweepOffer did not return an offer`)
                        return
                    }
                    this.instantBalanceTransfer.instantBalanceTransferFmpSweepOptions = response.data.payload
                    logger.info(`Got instant balance transfer FMP sweep options ${JSON.stringify(this.instantBalanceTransfer.instantBalanceTransferFmpSweepOptions)}`)
                } catch (e) {
                    logger.error(`Failed to get instant balance transfer FMP sweep options: ${e.message}`)
                }
            },
        },
    })()
    openReplay.setUpPiniaStoreTracking(personalFinancialLiabilityStore)
    return personalFinancialLiabilityStore
}
