import { AccountClosureReason, AccountClosureRequestSource, AccountClosureRequester, AccountClosureType } from '@/store/accountClosureStore.types'
import { AciAccountSubType, AmortizationSource, ApiRequireSingleUseToken, ExcessPaymentAllocationMethod, PlaidAccountType } from '@/data/constants'
import {
    EmploymentType,
    MaritalStatus,
    Mismo34AssetType,
    Mismo34LiabilityType,
    Mismo34OtherIncomeType,
    PastEmploymentType,
    getPrimaryUniformResidentialLoanApplicant,
    getUniformResidentialLoanApplication,
} from '@/utils/uniformResidentialLoanApplicationUtils'
import { HomeReAttachType, MegaphoneMessage, MortgagePaymentRewardsType, ProductCategory } from '@/store/overviewStore.types'
import { AciPaymentFundingType, CreditScoreDataPoint, TransactionHistoryListItem, UserConsentType } from 'aven_types'
import { httpClient, runWithRetryLogic } from './http-client'
import { LegalDocType } from '@/store/documentsStore.types'
import { logger } from '@/utils/logger'
import { InstantBalanceTransferOption } from 'aven_types/dist/my/instantBalanceTransfer'
import { AmortizationLoanType, ISimpleInterestLoanTermWithInstantBalanceTransfer } from 'aven_types/dist/my/amortizationLoan'
import { ApiRequireSingleUseTokenNoValidation } from 'aven_types/dist/my/singleUseToken'

interface APIResponseData<T extends Object> {
    success: boolean
    error: string
    payload: T
}

interface APIResponse<T extends Object> {
    status: number
    statusText: string
    data: APIResponseData<T>
}

interface Address {
    address: string
    unit: string | null
    city: string
    state: string
    zipCode: string
    country?: string // in virtually all the code it is always assumed that the country is US
}

const DOCUMENT_UPLOAD_TIMEOUT_MSEC = 2 * 60 * 1000 // 2 min (almost certainly overkill but necessary for very large files on slow connections)
const METHODFI_ACCEPT_TOS_TIMEOUT_MSEC = 3 * 60 * 1000 // 3 min (generally takes ~1 min)

export const submitSessionRecordingUrl = async (sessionId, sessionRecordingUrl) => {
    try {
        await httpClient.post('/aven_app/ana/sessionRecordingUrl', { sessionId, sessionRecordingUrl })
    } catch (error) {
        logger.info(`submission failed sessionRecordingUrl, ${sessionRecordingUrl}, ${error}`)
    }
}

export const submitLogRocketSessionUrl = async (sessionId, logRocketSessionUrl) => {
    try {
        await httpClient.post('/aven_app/ana/logRocketUrl', { sessionId, logRocketSessionUrl })
    } catch (error) {
        logger.info(`submission failed, LogRocket sessionUrl, ${logRocketSessionUrl}, ${error}`)
    }
}

// Transactions
const transactionHistory = async (pageIndex: string, type: string): Promise<APIResponse<TransactionHistoryListItem[]>> => {
    return await httpClient.post('/avenGetCHTransactionHistory', { pageIndex, type })
}

// AccountDetails
const accountDetails = async () => {
    return await httpClient.post('/avenCoreCardAccountDetails')
}

// Account Payoff Details
const accountPayoffQuote = async (requestDateInNycTime?: Date, payoffDateInNycTime?: Date) => {
    return await httpClient.post('/aven_app/getPayoffQuote', { requestDateInNycTime, payoffDateInNycTime })
}

const accountPayoffQuotePdf = async (requestDateInNycTime?: Date, payoffDateInNycTime?: Date) => {
    return await httpClient.get('/aven_app/getPayoffQuotePdf', {
        responseType: 'blob',
        params: {
            requestDateInNycTime,
            payoffDateInNycTime,
        },
    })
}

const sendAccountPayoffQuotePdfToEmail = async (emails: string[], requestDateInNycTime?: Date, payoffDateInNycTime?: Date) => {
    return await httpClient.post('/aven_app/sentPayoffQuoteToEmail', {
        requestDateInNycTime,
        payoffDateInNycTime,
        emails,
    })
}

// Card
const cardImage = async () => {
    return await httpClient.post('/avenCoreCardGetCardImageURL')
}

const cardHolderDetail = async () => {
    return await httpClient.post('/avenCoreCardGetCardHolderDetail')
}
// check AutoPay
const avenAutopayDetails = async () => {
    return await httpClient.get('/avenAutopayDetails')
}

const getPaymentBreakdownByPlan = async () => {
    return await httpClient.post('/getCreditCardCustomerPaymentBreakDownByPlan')
}

// Keep in sync with FirstTimeUserExperienceType in aven_backend/src/entity/firstTimeUserExperience.types.ts
export enum FirstTimeUserExperienceType {
    viewInstantBtDuringOnboarding = 'viewInstantBtDuringOnboarding',
}

const setFirstTimeUserExperienceIsExperienced = async (experience: FirstTimeUserExperienceType): Promise<APIResponse<any>> => {
    return await httpClient.post('/firstTimeUserExperience/setIsExperienced', {
        experience,
    })
}

const getBalanceTransfers = async () => {
    return await httpClient.get('/balanceTransfer/balanceTransfersByCustomer')
}

const getCashOutById = async (cashOutId: number) => {
    return await httpClient.post('/cashOut/cashOutById', { cashOutId })
}

const getBalanceTransferById = async (balanceTransferId: number) => {
    return await httpClient.post('/balanceTransfer/balanceTransferById', { balanceTransferId })
}

const requestCashOutCancelation = async (balanceTransferId: number) => {
    return await httpClient.post('/cashOut/cancelCashOut', { balanceTransferId })
}

const registerCashOut = async (
    amount: number,
    externalBankAccountId: string,
    hasBankStatementToUpload: boolean,
    allowFullDrawCashOut: boolean,
    cashOutFeeRatio?: number,
    forActivationRequirement?: boolean
) => {
    return await httpClient.post('/cashOut/registerCashOut', {
        amount,
        externalBankAccountId,
        hasBankStatementToUpload,
        allowFullDrawCashOut,
        cashOutFeeRatio,
        forActivationRequirement,
    })
}

const postAcceptMethodFiTos = async () => {
    return await httpClient.post('/balanceTransfer/acceptMethodFiTos', {}, { timeout: METHODFI_ACCEPT_TOS_TIMEOUT_MSEC })
}

const requestBalanceTransferCancelation = async (balanceTransferId: number) => {
    return await httpClient.post('/balanceTransfer/cancelBalanceTransfer', { balanceTransferId })
}

const postCreditCardBalanceTransfer = async (payload: {
    singleUseToken: string
    balanceTransferId: number
    amortizationLoanId: number | undefined
    simpleInterestLoanId: number | undefined
    loanType: AmortizationLoanType
}) => {
    const { singleUseToken, balanceTransferId, amortizationLoanId, simpleInterestLoanId, loanType } = payload
    return await httpClient.post('/balanceTransfer/postBalanceTransfer', {
        singleUseToken,
        balanceTransferId,
        amortizationLoanId,
        simpleInterestLoanId,
        loanType,
    })
}

const postInstantBalanceTransfer = async (payload: {
    last4Ssn: string
    singleUseToken: string
    balanceTransferId: number
    amortizationLoanId: number | undefined
    simpleInterestLoanId: number | undefined
    loanType: AmortizationLoanType
}) => {
    const { last4Ssn, singleUseToken, balanceTransferId, amortizationLoanId, simpleInterestLoanId, loanType } = payload
    return await httpClient.post('/balanceTransfer/postInstantBalanceTransfer', {
        last4Ssn,
        singleUseToken,
        balanceTransferId,
        amortizationLoanId,
        simpleInterestLoanId,
        loanType,
    })
}

const postManyInstantBalanceTransfers = async (payload: { last4Ssn: string; singleUseToken: string; balanceTransferIds: number[] }) => {
    const { last4Ssn, singleUseToken, balanceTransferIds } = payload
    return await httpClient.post('/balanceTransfer/postManyInstantBalanceTransfers', {
        last4Ssn,
        singleUseToken,
        balanceTransferIds,
    })
}

const enableRecurringBalanceTransfer = async (methodfiAccountId: number): Promise<APIResponse<{ dateOfNextTransfer: string }>> => {
    return await httpClient.post('/balanceTransfer/enableRecurringBalanceTransfer', {
        methodfiAccountId,
    })
}

const disableRecurringBalanceTransfer = async (methodfiAccountId: number): Promise<APIResponse<undefined>> => {
    return await httpClient.post('/balanceTransfer/disableRecurringBalanceTransfer', {
        methodfiAccountId,
    })
}

const editRecurringBalanceTransfer = async (methodfiAccountId: number, amountOverride: number): Promise<APIResponse<{ instantBalanceTransferOption: InstantBalanceTransferOption } | undefined>> => {
    return await httpClient.post('/balanceTransfer/editRecurringBalanceTransfer', {
        methodfiAccountId,
        amountOverride,
    })
}

const getAvailableInstantBalanceTransfers = async (): Promise<APIResponse<InstantBalanceTransferOption[]>> => {
    return await httpClient.get('/balanceTransfer/availableInstantBalanceTransfers')
}

const createInstantBalanceTransferFmpSweepOffer = async (): Promise<APIResponse<ISimpleInterestLoanTermWithInstantBalanceTransfer[]>> => {
    return await httpClient.post('/balanceTransfer/createInstantBalanceTransferFmpSweepOffer')
}

export enum RegisterInstantBalanceTransferError {
    DUPLICATE_BALANCE_TRANSFER = 'DUPLICATE_BALANCE_TRANSFER',
}

const registerInstantBalanceTransfer = async (methodfiAccountId: number, amount: number) => {
    return await httpClient.post('/balanceTransfer/registerInstantBalanceTransfer', {
        methodfiAccountId,
        amount,
    })
}

const dismissMegaphone = async (megaphone: MegaphoneMessage) => {
    return await httpClient.post('/aven_app/dismissMegaphone', {
        megaphoneId: megaphone.megaphoneId,
    })
}

// Enable AutoPay
const avenAutopayOptIn = async (fundingSourceSubType: string, fundingTokenId: string, autopayCategory: string, autopayCustomAmount: string) => {
    return await httpClient.post('/avenAutopayOptIn', {
        fundingSourceSubType,
        fundingTokenId,
        autopayCategory,
        autopayCustomAmount,
    })
}

const activateAvenCard = async (last4DigitsAvenCard: string, expirationMmYy: string) => {
    return await httpClient.post('/aven_app/activateCard', {
        last4DigitsAvenCard,
        expirationMmYy,
    })
}

// Disable AutoPay
const avenAutopayOptOut = async () => {
    return await httpClient.post('/avenAutopayOptOut')
}

// Notification Status
const avenNotificationStatus = async (): Promise<APIResponse<{ isPushNotificationEnabled: boolean }>> => {
    return await httpClient.get('/aven_app/getPushNotificationSettingStatus')
}

// Keep in sync with aven_backend/src/controller/aven/avenPushNotificationsController.ts
enum ToggleNotificationSettingsErrors {
    /**
     * Users can't toggle push notifications to enabled w/out a valid push notification token.
     */
    NO_VALID_PUSH_TOKEN = 'NO_VALID_PUSH_TOKEN',
}

// Notification Setting
const avenNotificationSetting = async (isEnabled: boolean): Promise<APIResponse<{ isPushNotificationEnabled: boolean }>> => {
    return await httpClient.post('/aven_app/updatePushNotificationSetting', {
        isEnabled,
    })
}

// Add Payment method - Bank
const aciGenerateFundingTokenBankAccount = async (
    bankAccountType: string,
    routingNumber: string,
    bankAccountNumber: string,
    accountHolderName: string,
    state: string,
    postalCode: string,
    accountNickname: string
) => {
    return await httpClient.post('/avenAciGenerateFundingTokenBankAccount', {
        bankAccountType,
        routingNumber,
        bankAccountNumber,
        accountHolderName,
        state,
        postalCode,
        accountNickname,
    })
}

const uploadDocument = async (fileUrl, documentTypePath, documentIndex) => {
    const formData = new FormData()
    formData.append('file', fileUrl)
    return await httpClient.post(`/uploadDocument/${documentTypePath}/${documentIndex}`, formData, { timeout: DOCUMENT_UPLOAD_TIMEOUT_MSEC })
}

// List Funding sources
const aciSearchFundingSources = async () => {
    return await httpClient.post('/avenAciSearchFundingSources')
}

const getCardEmbossingStatus = async () => {
    return await httpClient.get('/avenCoreCardGetEmbossingManualStatus')
}

const freezeCard = async () => {
    return await httpClient.post('/freezeCard')
}

const unfreezeCard = async (password: string) => {
    return await httpClient.post('/unfreezeCard', { passcode: password })
}

//delete Funding Source
const avenAciDeleteFundingSource = async (fundingTokenId: string) => {
    return await httpClient.post('/avenAciDeleteFundingSource', {
        fundingTokenId,
    })
}
// Make a payment
const aciMakePayment = async (payload: { fundingTokenId: string; fundingSourceSubType: string; paymentDate: Date; paymentAmount: string }) => {
    return await httpClient.post('/avenAciMakePayment', payload)
}

const getExpectedOtbReleaseDateIfPaymentMade = async (payload: { paymentFundingType: AciPaymentFundingType; paymentAmount: number; paymentDate: Date }) => {
    return await httpClient.post('/getExpectedOtbReleaseDateIfPaymentMade', payload)
}

const getFutureScheduleablePaymentDates = async () => {
    return await httpClient.get('/aven_app/scheduledOneTimePayments/getFutureScheduleablePaymentDates')
}

const makeOneTimeScheduledPayment = async (payload: { paymentAmount: number; scheduledPaymentDate: Date; externalBankAccountId: number }) => {
    return await httpClient.post('/aven_app/scheduledOneTimePayments/createNewScheduledOneTimePayment', payload)
}

export const cancelScheduledOneTimePayment = async (payload: { scheduledOneTimePaymentId }) => {
    return await httpClient.post('/aven_app/scheduledOneTimePayments/cancelScheduledOneTimePayment', payload)
}

export const getCanOfferPastDueAcccountReage = async () => {
    return await httpClient.get('/aven_app/getCanOfferPastDueAcccountReage')
}

export const offerPastDueAcccountReage = async () => {
    return await httpClient.post('/aven_app/offerPastDueAcccountReage')
}

export const getCanOfferLateFeeReversal = async () => {
    return await httpClient.get('/aven_app/getCanOfferLateFeeReversal')
}

export const offerLateFeeReversal = async () => {
    return await httpClient.post('/aven_app/offerLateFeeReversal')
}

//for getting account agreement and other legal documents associated with the customer
const getLegalDocumentDownload = async (docType: LegalDocType, documentId?: number) => {
    return await httpClient.get('/legal/document', {
        responseType: 'blob',
        params: {
            docType,
            documentId,
        },
    })
}

const generateLegalDocument = async (docType: string) => {
    return await httpClient.post('/legal', {
        docTypes: [docType],
    })
}

/** @deprecated Replaced by {@link redeemScaledPoints} below. Can remove this endpoint after the next deploy */
const postRedeemRewards = async (numLoyaltyPointsToRedeem: number, dollarValueOfPoints: string) => {
    return await httpClient.post('/primeCard/redeemRewards', {
        numLoyaltyPointsToRedeem,
        dollarValueOfPoints,
    })
}

/**
 * Scaled points are CoreCard points (1 penny per point) scaled by the cardholder's cash back ratio.
 */
const redeemScaledPoints = async (numScaledPointsToRedeem: number) => {
    return await httpClient.post('/aven_app/redeemScaledPoints', {
        numScaledPointsToRedeem,
    })
}

// Keep in sync with SyncAscendaAndCoreCardResponse in aven_backend/src/controller/externalRewardsController.ts
interface SyncAscendaAndCoreCardResponse {
    hasAscendaAccount: boolean
    syncCompleted: boolean
}

const syncAscendaAndCoreCard = async (): Promise<APIResponse<SyncAscendaAndCoreCardResponse>> => {
    return await httpClient.get('/aven_app/syncAscendaAndCoreCard')
}

// Keep in sync with RetrieveAscendaLinkResponse in aven_backend/src/controller/externalRewardsController.ts
interface RetrieveAscendaLinkResponse {
    ascendaLink: string
}

const retrieveAscendaLink = async (): Promise<APIResponse<RetrieveAscendaLinkResponse>> => {
    return await httpClient.get('/aven_app/retrieveAscendaLink')
}

// Keep in sync with RewardYearWise in aven_backend/src/models/response/CoreCardProviderResponse.ts
interface RewardYearWise {
    LoyaltyAmount: number
    LoyaltyPointsEarned: number
    Year: string
}

export interface RewardsRedeemed {
    dateRedeemed: Date
    dollarValueOfPoints: number | null
    pending: boolean
}

// Keep in sync with AvenCoreCardGetRewardsSummaryResponse in aven_backend/src/controller/coreCardController.ts
interface AvenCoreCardGetRewardsSummaryResponse {
    beginning: number
    remaining: number
    beginningScaledByCashBackRatio: number
    remainingScaledByCashBackRatio: number
    yearWiseSummary: RewardYearWise[]
    rewardsRedeemedResponse: RewardsRedeemed[]
}

const getRewardsSummary = async (): Promise<APIResponse<AvenCoreCardGetRewardsSummaryResponse>> => {
    return await httpClient.get('/primeCard/getRewardsSummary')
}

const postRequestSingleUseToken = async (last4Ssn: string, api: ApiRequireSingleUseToken) => {
    return await httpClient.post('/aven_app/requestSingleUseToken', {
        last4Ssn,
        api,
    })
}

const postRequestSingleUseTokenWithoutValidation = async (api: ApiRequireSingleUseTokenNoValidation) => {
    return await httpClient.post('/aven_app/requestSingleUseTokenNoValidation', {
        api,
    })
}

const postVerifyLast4Ssn = async (last4Ssn: string) => {
    return await httpClient.post('/aven_app/verifyLast4Ssn', {
        last4Ssn,
    })
}

const postVerifyDateOfBirthAndLast4Ssn = async (dateOfBirth: Date, last4Ssn: string) => {
    return await httpClient.post('/aven_app/verifyDateOfBirthAndLast4Ssn', {
        dateOfBirth,
        last4Ssn,
    })
}

const postUpdateDocumentUploadIdForCashOut = async (cashOutId: number, documentUploadId: number) => {
    return await httpClient.post('/cashOut/updateBankStatementIdForCashOut', {
        cashOutId,
        documentUploadId,
    })
}

const postCashOutAutopayOptIn = async (cashOutId: number, autopayCategory: string, autopayCustomAmount?: string) => {
    return await httpClient.post('/cashOut/autopayOptIn', {
        cashOutId,
        autopayCategory,
        autopayCustomAmount,
    })
}

const postCashOut = async (payload: {
    singleUseToken: string
    cashOutId: number
    amortizationLoanId: number | undefined
    simpleInterestLoanId: number | undefined
    loanType: AmortizationLoanType | undefined
}) => {
    const { singleUseToken, cashOutId, amortizationLoanId, simpleInterestLoanId, loanType } = payload
    return await httpClient.post('/balanceTransfer/cashOut', {
        singleUseToken,
        cashOutId,
        amortizationLoanId,
        simpleInterestLoanId,
        loanType,
    })
}

const postDraftCashOut = async (payload: {
    singleUseToken: string
    cashOutId: number
    amortizationLoanId: number | undefined
    simpleInterestLoanId: number | undefined
    loanType: AmortizationLoanType | undefined
}) => {
    const { singleUseToken, cashOutId, amortizationLoanId, simpleInterestLoanId, loanType } = payload
    return await httpClient.post('/balanceTransfer/draftCashOut', {
        singleUseToken,
        cashOutId,
        amortizationLoanId,
        simpleInterestLoanId,
        loanType,
    })
}

const postMailerCodeValidation = async (mailerCode: string) => {
    return await httpClient.post('/aven_app/validateMailerCode', {
        mailerCode,
    })
}

const postRequestNewMailerCode = async () => {
    return await httpClient.post('/aven_app/requestNewMailerCode', {})
}

export const getTheoreticalPaymentStats = async (apr: number, lineSize: number) => {
    return await httpClient.get('/origination/getTheoreticalPayment', { params: { apr, lineSize } })
}

const getInstallmentLoanTerms = async (amount: number, amortizationSource: AmortizationSource, allowLongTermInstallmentLoans?: boolean) => {
    return await httpClient.post('/coreCard/getAmortizationLoanTerms', {
        amount,
        amortizationSource,
        isFixedTermUser: allowLongTermInstallmentLoans, // 'isFixedTermUser' is a legacy name,
    })
}

const getInstallmentLoanTermsForFixedTermActivation = async (amountWithFee: number, amortizationSource: AmortizationSource) => {
    return await httpClient.post('/coreCard/getAmortizationLoanTermsForFixedTermActivation', {
        amountWithFee,
        amortizationSource,
    })
}

export const requestPlaidLinkToken = async (redirectUri: string) => {
    return await httpClient.post('/plaid/requestLinkToken', {
        redirectUri,
    })
}

const startPlaidReportFetch = async (plaidPublicToken: string) => {
    return await httpClient.post('/startPlaidReportFetch', {
        public_token: plaidPublicToken,
    })
}

const plaidReportFetchState = async () => {
    return await httpClient.get('/plaidReportFetchState')
}

// Keep in sync with RegisterPlaidBankAccountsError in aven_backend/src/models/request/cashOutRequest.ts
export enum RegisterPlaidBankAccountsError {
    noAccountsRetreived = 'noAccountsRetreived',
    allAccountsDenied = 'allAccountsDenied',
    nameMatchingFailed = 'nameMatchingFailed',
    registerAccountsFailed = 'registerAccountsFailed',
}

const registerPlaidBankAccounts = async (accountType: PlaidAccountType, institutionName: string, depositAccountIds: string[]) => {
    return await httpClient.post('/cashOut/registerPlaidBankAccounts', {
        accountType,
        institutionName,
        depositAccountIds,
    })
}

const fetchMortgagePaymentsFromPlaid = async (plaidDataReportId: number, mortgagePaymentRewardsType: MortgagePaymentRewardsType) => {
    return await httpClient.get('/mortgagePayments', { params: { plaidDataReportId, mortgagePaymentRewardsType } })
}

const getAvailaleSpotsForMortgageCashbackV2 = async () => {
    return await httpClient.get('/availableMortgageCashBackV2Count')
}

const registerMortgagePaymentForRewards = async (
    mortgagePaymentRewardsRequestId: number,
    plaidAccountId: string,
    plaidTransactionId: string,
    plaidTransactionAmount: number,
    plaidTransactionDate: Date
) => {
    return await httpClient.post('/registerMortgagePaymentForRewards', {
        mortgagePaymentRewardsRequestId,
        plaidAccountId,
        plaidTransactionId,
        plaidTransactionAmount,
        plaidTransactionDate,
    })
}

const postDesiredProduct = async (desiredProduct: ProductCategory) => {
    return await httpClient.post('/postDesiredProduct', {
        desiredProduct,
    })
}

const initiateAccountClosureRequest = async (requestDateInNycTime: Date, requestType: AccountClosureType, requestReason: AccountClosureReason, otherReason: string | null) => {
    return await httpClient.post('/aven_app/accountClosure/initiateRequest', {
        requestDateInNycTime,
        requestType,
        requester: AccountClosureRequester.USER,
        requestSource: AccountClosureRequestSource.SELF_SERVE,
        requestReason,
        otherReason: otherReason ? otherReason : undefined,
    })
}

const initiateReinstateCreditLimitRequest = async (previousCreditLimit: number) => {
    return await httpClient.post('/aven_app/reinstateCreditLimit', {
        previousCreditLimit,
    })
}

const getAprReductionOffer = async () => {
    return await httpClient.post('/aven_app/getAprReductionOffer')
}

const acceptOrDeclineAprReductionOffer = async (acceptOffer: boolean) => {
    return await httpClient.post('/underwriting/acceptOrDeclineAprReductionOffer', {
        acceptOffer,
    })
}

const getActiveBalanceTransferAccountsEligibleForRecurrence = async (): Promise<APIResponse<any>> => {
    return await httpClient.get('/aven_app/auto_bt/eligible_active_accounts')
}

const getActiveBalanceTransferAccount = async (token: string): Promise<APIResponse<any>> => {
    return await httpClient.get(`/aven_app/auto_bt/active_account/${token}`)
}

interface IBalanceTransferAccountUpdate {
    accountId: number
    accountName: string
    isAutoBalanceTransferEnabled: boolean
}

const updateAutoBalanceTransferEnabledAccounts = async (accounts: IBalanceTransferAccountUpdate[]): Promise<APIResponse<any>> => {
    return await httpClient.post('/aven_app/auto_bt/update_auto_bt_enabled', {
        updatedActiveBalanceTransferAccounts: accounts,
    })
}

interface IInitiateBalanceTransferRequest {
    token: string
    amount: number
}

const initiateBalanceTransferForRecurringAccount = async (transfer: IInitiateBalanceTransferRequest): Promise<APIResponse<any>> => {
    return await httpClient.post('/aven_app/auto_bt/initiate', transfer)
}

const getSavedBankAccounts = async (): Promise<APIResponse<any>> => {
    return await httpClient.get(`/getSavedBankAccounts`)
}

// Keep in sync with ExternalBankAccountVerificationResult in aven_frontend/aven_my/src/services/api.ts
export enum ExternalBankAccountVerificationResult {
    // Default fallback
    UPLOAD_DOCUMENTS = 'UPLOAD_DOCUMENTS',
    PASS = 'PASS',
    CANNOT_BE_VERIFIED = 'CANNOT_BE_VERIFIED',
    ROUTING_NUMBER_INVALID = 'ROUTING_NUMBER_INVALID',
}

// Keep in sync with RegisterNewBankAccountUsingBankStatementUploadResponse in aven_frontend/aven_my/src/services/api.ts
interface RegisterNewBankAccountUsingBankStatementUploadResponse {
    externalBankAccountId: number
    externalBankAccountVerificationResult: ExternalBankAccountVerificationResult
}

const registerNewBankAccountUsingBankStatementUpload = async (
    accountType: AciAccountSubType,
    institutionName: string,
    accountNumber: string,
    routingNumber: string
): Promise<APIResponse<RegisterNewBankAccountUsingBankStatementUploadResponse>> => {
    return await httpClient.post('/cashOut/registerNewBankAccountUsingBankStatementUpload', {
        accountType,
        institutionName,
        accountNumber,
        routingNumber,
    })
}

const sendUserFeedbackEmail = async (feedbackText: string) => {
    return httpClient.post('/userFeedback/email', {
        feedbackText,
    })
}

export const submitUserFeedback = async (feedbackText: string, sessionId: string) => {
    return httpClient.post('/userFeedback/submitUserFeedback', {
        feedbackText,
        sessionId,
    })
}

const createTrustPilotUniqueLink = async (returnToken2: string) => {
    return httpClient.post('/createTrustPilotUniqueLink', {
        returnToken2,
    })
}

const saveReview = async (starRating: number, leftTrustPilotReview: boolean, returnToken2: string, feedbackText?: string) => {
    return await httpClient.post('/aven_app/saveReview', { starRating, leftTrustPilotReview, returnToken2, feedbackText })
}

const createAutoRefinanceApplication = async () => {
    return await httpClient.post('/auto/createApplication')
}

const initiateHomeReAttach = async (homeReAttachType: HomeReAttachType) => {
    return await httpClient.post('/aven_app/initHomeReattach', {
        homeReAttachType,
    })
}

const getHomeReAttachOffer = async (jobId: string) => {
    return await httpClient.get('/aven_app/getHomeReAttachOffer', {
        params: {
            jobId,
        },
    })
}

const getQuickPromotionInfo = async (promotionName: string) => {
    return await httpClient.get(`/aven_app/promo/${promotionName}`)
}

const getPointOfferInfo = async () => {
    return await httpClient.get('/aven_app/getPointOffer')
}

const acceptHcmRemediationOffer = async () => {
    return await httpClient.get('/customerRemediation/acceptHCMRemediationOffer')
}

const declineHcmRemediationOffer = async () => {
    return await httpClient.post('/customerRemediation/declineHCMRemediationOffer')
}

export interface IDqReasonUpdatePayload {
    reason?: string
    feedback?: string
    subReason?: string | null
    daysToPay?: string
    maxPayment?: string
    plaidDataReportId?: number
}

const addDqReason = async (payload: IDqReasonUpdatePayload) => {
    return await httpClient.post('/aven_app/addDqReason', payload)
}

const acceptTermsOfService = async () => {
    return await runWithRetryLogic(async () => httpClient.post('/acceptTermsOfService'), 3)
}

const getBreakupPaymentOptions = async () => {
    return await httpClient.get('/aven_app/getBreakupPaymentOptions')
}

const selectBreakupPaymentOption = async (type: string) => {
    return await httpClient.post('/aven_app/selectBreakupPaymentOption', {
        type,
    })
}

const setExcessPaymentAllocationMethod = async (excessPaymentAllocationMethod: ExcessPaymentAllocationMethod) => {
    return await httpClient.post('/aven_app/setExcessPaymentAllocationMethod', {
        excessPaymentAllocationMethod,
    })
}

const getAuthorizedCardholders = async () => {
    return await httpClient.get('/aven_app/authorizedCardholders')
}

const postUpdateAuthorizedCardholderStatus = async (creditCardCustomerId: number, enabled: boolean) => {
    return await httpClient.post('/aven_app/updateAuthorizedCardholderStatus', {
        creditCardCustomerId,
        enabled,
    })
}

const requestFullCreditLimit = async () => {
    return await httpClient.post('/aven_app/activateFullLineSize')
}

const createMortgageRefinanceRequest = async (amountStatedDollars, reason) => {
    return await httpClient.post('/createMortgageRefinanceRequest', {
        amountStatedDollars,
        reason,
    })
}

const getOrCreateUniformResidentialLoanApplication = async () => {
    return await httpClient.post('/uniformResidentialLoanApplication/getOrCreateForCreditCardCustomer')
}

const submitUniformResidentialLoanApplicationMaritalStatus = async (uniformResidentialLoanApplicantId: number, maritalStatus: string) => {
    return await httpClient.post('/uniformResidentialLoanApplication/submitMaritalStatus', {
        uniformResidentialLoanApplicantId,
        maritalStatus,
    })
}

const submitUniformResidentialLoanApplicationAddressHistory = async (addressHistory: any[]) => {
    return await httpClient.post('/uniformResidentialLoanApplication/submitAddressHistory', {
        uniformResidentialLoanApplicantId: (await getPrimaryUniformResidentialLoanApplicant()).id,
        addressHistory,
    })
}

const submitUniformResidentialLoanApplicationDependents = async (dependents: any[]) => {
    return await httpClient.post('/uniformResidentialLoanApplication/submitDependents', {
        uniformResidentialLoanApplicantId: (await getPrimaryUniformResidentialLoanApplicant()).id,
        dependents,
    })
}

const submitUniformResidentialLoanApplicationProperties = async (properties: any[]) => {
    return await httpClient.post('/uniformResidentialLoanApplication/submitProperties', {
        uniformResidentialLoanApplicantId: (await getPrimaryUniformResidentialLoanApplicant()).id,
        properties,
    })
}

const submitUniformResidentialLoanApplicationCurrentEmployment = async (
    uniformResidentialLoanApplicantId: number,
    employmentType: string,
    employerName: string | null,
    jobTitle: string | null,
    startDate: Date | null,
    employerPhoneNumber: string | null,
    employerAddress: any | null
) => {
    return await httpClient.post('/uniformResidentialLoanApplication/submitCurrentEmployment', {
        uniformResidentialLoanApplicantId,
        employmentType,
        employerName,
        jobTitle,
        startDate,
        employerPhoneNumber,
        employerAddress,
    })
}

const submitUniformResidentialLoanApplicationCurrentIncome = async (
    annualBaseSalary: number,
    annualBonuses: number,
    annualOvertimePay: number,
    annualCommission: number
): Promise<APIResponse<any>> => {
    return await httpClient.post('/uniformResidentialLoanApplication/submitCurrentIncome', {
        uniformResidentialLoanApplicantId: (await getPrimaryUniformResidentialLoanApplicant()).id,
        annualBaseSalary,
        annualBonuses,
        annualOvertimePay,
        annualCommission,
    })
}

const submitUniformResidentialLoanApplicationEmploymentHistory = async (uniformResidentialLoanApplicantId: number, employmentHistory: any[]) => {
    return await httpClient.post('/uniformResidentialLoanApplication/submitEmploymentHistory', {
        uniformResidentialLoanApplicantId,
        employmentHistory,
    })
}

const submitUniformResidentialLoanApplicationAdditionalIncome = async (additionalIncome: any[]) => {
    return await httpClient.post('/uniformResidentialLoanApplication/submitAdditionalIncome', {
        uniformResidentialLoanApplicantId: (await getPrimaryUniformResidentialLoanApplicant()).id,
        additionalIncome,
    })
}

const submitUniformResidentialLoanApplicationBankAccounts = async (bankAccounts: any[]) => {
    return await httpClient.post('/uniformResidentialLoanApplication/submitBankAccounts', {
        uniformResidentialLoanApplicantId: (await getPrimaryUniformResidentialLoanApplicant()).id,
        bankAccounts,
    })
}

const submitUniformResidentialLoanApplicationInvestmentAccounts = async (investmentAccounts: any[]) => {
    return await httpClient.post('/uniformResidentialLoanApplication/submitInvestmentAccounts', {
        uniformResidentialLoanApplicantId: (await getPrimaryUniformResidentialLoanApplicant()).id,
        investmentAccounts,
    })
}

const submitUniformResidentialLoanApplicationOtherAssets = async (uniformResidentialLoanApplicantId: number, assets: any[]) => {
    return await httpClient.post('/uniformResidentialLoanApplication/submitOtherAssets', {
        uniformResidentialLoanApplicantId,
        assets,
    })
}

const submitUniformResidentialLoanApplicationLiabilities = async (liabilities: any[]) => {
    return await httpClient.post('/uniformResidentialLoanApplication/submitLiabilities', {
        uniformResidentialLoanApplicantId: (await getPrimaryUniformResidentialLoanApplicant()).id,
        liabilities,
    })
}

const submitUniformResidentialLoanApplicationDeclarationQuestions = async (
    uniformResidentialLoanApplicantId: number,
    declarationQuestions: {
        hasOutstandingJudgement: boolean
        hasDeclaredBankruptcy: boolean
        hasForclosedProperty: boolean
        isPartyInLawsuit: boolean
        isObligatedOnLoan: boolean
        isDelinquent: boolean
        isObligatedToPayAlimonyOrChildSupport: boolean
        isDownPaymentBorrowed: boolean
        isComakerOrEndorser: boolean
        isUsCitizen: boolean
        isPermanentResidentAlien: boolean
        hasOwnershipForThreeYears: boolean
    }
): Promise<APIResponse<any>> => {
    return await httpClient.post('/uniformResidentialLoanApplication/submitDeclarationQuestions', {
        uniformResidentialLoanApplicantId,
        ...declarationQuestions,
    })
}

const submitUniformResidentialLoanApplicationHmda = async (
    uniformResidentialLoanApplicantId: number,
    hmdaInformation: {
        sex: string
        ethnicities: string[]
        races: string[]
    }
): Promise<APIResponse<any>> => {
    return await httpClient.post('/uniformResidentialLoanApplication/submitHmda', {
        uniformResidentialLoanApplicantId,
        ...hmdaInformation,
    })
}

const submitUniformResidentialLoanApplicationBrokerageAgreement = async () => {
    return await httpClient.post('/uniformResidentialLoanApplication/submitBrokerageAgreement', {
        uniformResidentialLoanApplicationId: (await getUniformResidentialLoanApplication()).uniformResidentialLoanApplication.id,
    })
}

const createNewLoanApplicationForMortgageRefi = async (mortgageLender: string, mortgageBalance: number, avenBalance: number, cashOut: number): Promise<APIResponse<{ loanApplicationId: number }>> => {
    return await httpClient.post('/mortgageBrokering/loanApplication', {
        mortgageLender,
        currentMortgageBalance: mortgageBalance,
        avenCardBalance: avenBalance,
        additionalCash: cashOut,
    })
}

const getMortgageRefinanceRates = async (): Promise<
    APIResponse<{
        interestRate: number
        apr: number
        loanAmount: number
        closingCosts: number
        loanAmountWithClosingCosts: number
        monthlyPayment: number
    }>
> => {
    return await httpClient.get('/mortgageBrokering/offer')
}

const getMortgageRefinanceDocuSignUrl = async () => {
    return await httpClient.get('/uniformResidentialLoanApplication/docuSign')
}

const getMortgageBalance = async () => {
    return await httpClient.get('/mortgageBrokering/balance')
}

const getMaritalStatus = async (): Promise<APIResponse<{ maritalStatus: MaritalStatus }>> => {
    return await httpClient.get('/uniformResidentialLoanApplication/maritalStatus')
}

const getAddressHistoryFromUserInput = async (
    uniformResidentialLoanApplicantId: number
): Promise<APIResponse<(Address & { startDate: string; endDate: string | null; isCurrent: boolean })[] | null>> => {
    return await httpClient.get('/uniformResidentialLoanApplication/addressHistory', {
        params: {
            uniformResidentialLoanApplicantId,
        },
    })
}

const getAddressHistoryFromReports = async (): Promise<APIResponse<(Address & { isCurrent: boolean; startDate: string | null; endDate: string | null })[]>> => {
    return await httpClient.get('/uniformResidentialLoanApplication/reports/addressHistory')
}

const getDependentsFromUserInput = async (uniformResidentialLoanApplicantId: number): Promise<APIResponse<{ name: string; age: string }[] | null>> => {
    return await httpClient.get('/uniformResidentialLoanApplication/dependents', {
        params: {
            uniformResidentialLoanApplicantId,
        },
    })
}

const getSubjectPropertyFromReports = async (): Promise<
    APIResponse<{
        address: {
            addressStreet: string
            secondaryAddressUnit?: string
            addressCity: string
            addressState: string
            addressPostalCode: string
            addressCounty: string
        }
    }>
> => {
    return await httpClient.get('/uniformResidentialLoanApplication/reports/subjectProperty')
}

const getPropertiesFromUserInput = async (uniformResidentialLoanApplicantId: number) => {
    return await httpClient.get('/uniformResidentialLoanApplication/properties', {
        params: {
            uniformResidentialLoanApplicantId,
        },
    })
}

const getPropertiesFromReports = async () => {
    return await httpClient.get('/uniformResidentialLoanApplication/reports/properties')
}

const getCurrentEmploymentFromUserInput = async (
    uniformResidentialLoanApplicantId: number
): Promise<
    APIResponse<{
        employmentType: EmploymentType
        startDate: Date
        employerName: string | null
        jobTitle: string | null
        employerPhoneNumber: string | null
        employerAddress: any | null // TODO: copy the address info type
        reasonForUnemployment: string | null
    } | null>
> => {
    return await httpClient.get('/uniformResidentialLoanApplication/currentEmployment', {
        params: {
            uniformResidentialLoanApplicantId,
        },
    })
}

const getCurrentEmploymentFromReports = async (
    uniformResidentialLoanApplicantId: number
): Promise<APIResponse<{ employmentType: EmploymentType | null; jobTitle: string | null; employerName: string | null }>> => {
    return await httpClient.get('/uniformResidentialLoanApplication/reports/currentEmployment', {
        params: {
            uniformResidentialLoanApplicantId,
        },
    })
}

const getCurrentIncomeFromUserInput = async (uniformResidentialLoanApplicantId: number): Promise<APIResponse<{ baseSalary: string; bonuses: string; overtime: string; commission: string }>> => {
    return await httpClient.get('/uniformResidentialLoanApplication/currentIncome', {
        params: {
            uniformResidentialLoanApplicantId,
        },
    })
}

const getCurrentIncomeFromReports = async (): Promise<APIResponse<{ baseSalary: string; bonuses: string | null; overtime: string | null; commission: string | null }>> => {
    return await httpClient.get('/uniformResidentialLoanApplication/reports/currentIncome')
}

const getPastEmploymentsFromUserInput = async (
    uniformResidentialLoanApplicantId: number
): Promise<
    APIResponse<
        | {
              employmentType: PastEmploymentType
              startDate: string
              endDate: string
              employerName: string | null
              jobTitle: string | null
              employerPhoneNumber: string | null
              employerAddress: Address | null
              reasonForUnemployment: string | null
          }[]
        | null
    >
> => {
    return await httpClient.get('/uniformResidentialLoanApplication/pastEmployments', {
        params: {
            uniformResidentialLoanApplicantId,
        },
    })
}

const getOtherIncomesFromUserInput = async (
    uniformResidentialLoanApplicantId: number
): Promise<APIResponse<{ incomeSourceType: Mismo34OtherIncomeType; annualGrossIncomeAmount: string }[] | null>> => {
    return await httpClient.get('/uniformResidentialLoanApplication/otherIncomes', {
        params: {
            uniformResidentialLoanApplicantId,
        },
    })
}

const getOtherAssetsFromUserInput = async (
    uniformResidentialLoanApplicantId: number
): Promise<APIResponse<{ assetType: Mismo34AssetType; institutionName: string | null; assetValue: string }[] | null>> => {
    return await httpClient.get('/uniformResidentialLoanApplication/otherAssets', {
        params: {
            uniformResidentialLoanApplicantId,
        },
    })
}

const getLiabilitiesFromUserInput = async (
    uniformResidentialLoanApplicantId: number
): Promise<APIResponse<{ liabilityType: Mismo34LiabilityType; liabilityName: string; monthlyPayment: string; currentBalance: string | null }[] | null>> => {
    return await httpClient.get('/uniformResidentialLoanApplication/liabilities', {
        params: {
            uniformResidentialLoanApplicantId,
        },
    })
}

const getDeclarationQuestionsFromUserInput = async (
    uniformResidentialLoanApplicantId: number
): Promise<
    APIResponse<{
        hasOutstandingJudgement: boolean
        hasDeclaredBankruptcy: boolean
        hasForclosedProperty: boolean
        isPartyInLawsuit: boolean
        isDelinquent: boolean
        isObligatedOnLoan: boolean
        isObligatedToPayAlimonyOrChildSupport: boolean
        isDownPaymentBorrowed: boolean
        isComakerOrEndorser: boolean
        isUsCitizen: boolean
        isPermanentResidentAlien: boolean | null
        hasOwnershipForThreeYears: boolean
    } | null>
> => {
    return await httpClient.get('/uniformResidentialLoanApplication/declarationQuestions', {
        params: {
            uniformResidentialLoanApplicantId,
        },
    })
}

const acceptToBeAmbassador = async () => {
    return await httpClient.post('/aven_app/acceptToBeAmbassador')
}

const declineToBeAmbassador = async () => {
    return await httpClient.post('/aven_app/declineToBeAmbassador')
}

// Todo: below types are copied from advisor Frontend
// we need to find a proper way to share these types
export const getCreditScores = async (): Promise<APIResponse<{ ficoScore: number | null; vantageScore: number | null; dateOfScores: Date; dateOfNextScores: Date }>> => {
    return await httpClient.get('/creditScores')
}

export type Optional<T> = T | null | undefined

export const getCreditScoreHistory = async (): Promise<APIResponse<{ history: CreditScoreDataPoint[] }>> => {
    return await httpClient.get('/creditScoreHistory')
}

export const makeConsent = async (userConsentType: UserConsentType): Promise<APIResponse<{}>> => {
    return await httpClient.post('/makeConsent', {
        userConsentType,
    })
}

export const getConsents = async (): Promise<APIResponse<{ consents: UserConsentType[] }>> => {
    return await httpClient.get('/getConsents')
}

export const getMOFOJodl = async (): Promise<APIResponse<{ jodl: string }>> => {
    return await httpClient.get('/aven_app/getMofoJodl')
}

export const initMofo = async (): Promise<APIResponse<{ didInit: boolean }>> => {
    return await httpClient.post('/aven_app/initMofo')
}

export {
    postDesiredProduct,
    transactionHistory,
    cardImage,
    cardHolderDetail,
    aciGenerateFundingTokenBankAccount,
    aciSearchFundingSources,
    aciMakePayment,
    getExpectedOtbReleaseDateIfPaymentMade,
    getFutureScheduleablePaymentDates,
    makeOneTimeScheduledPayment,
    avenAutopayDetails,
    ToggleNotificationSettingsErrors,
    avenNotificationSetting,
    avenNotificationStatus,
    avenAutopayOptIn,
    avenAutopayOptOut,
    accountDetails,
    accountPayoffQuote,
    accountPayoffQuotePdf,
    sendAccountPayoffQuotePdfToEmail,
    avenAciDeleteFundingSource,
    activateAvenCard,
    getCardEmbossingStatus,
    freezeCard,
    unfreezeCard,
    getLegalDocumentDownload,
    setFirstTimeUserExperienceIsExperienced,
    getBalanceTransfers,
    postRedeemRewards,
    redeemScaledPoints,
    syncAscendaAndCoreCard,
    retrieveAscendaLink,
    getRewardsSummary,
    postRequestSingleUseToken,
    postRequestSingleUseTokenWithoutValidation,
    postCashOut,
    postDraftCashOut,
    postMailerCodeValidation,
    postRequestNewMailerCode,
    postVerifyLast4Ssn,
    postVerifyDateOfBirthAndLast4Ssn,
    startPlaidReportFetch,
    plaidReportFetchState,
    registerPlaidBankAccounts,
    fetchMortgagePaymentsFromPlaid,
    uploadDocument,
    postCashOutAutopayOptIn,
    getCashOutById,
    getBalanceTransferById,
    requestCashOutCancelation,
    initiateAccountClosureRequest,
    initiateReinstateCreditLimitRequest,
    postAcceptMethodFiTos,
    requestBalanceTransferCancelation,
    getInstallmentLoanTerms,
    getInstallmentLoanTermsForFixedTermActivation,
    postCreditCardBalanceTransfer,
    postInstantBalanceTransfer,
    postManyInstantBalanceTransfers,
    enableRecurringBalanceTransfer,
    disableRecurringBalanceTransfer,
    editRecurringBalanceTransfer,
    getAvailableInstantBalanceTransfers,
    createInstantBalanceTransferFmpSweepOffer,
    registerInstantBalanceTransfer,
    generateLegalDocument,
    getActiveBalanceTransferAccountsEligibleForRecurrence,
    getActiveBalanceTransferAccount,
    IBalanceTransferAccountUpdate,
    updateAutoBalanceTransferEnabledAccounts,
    IInitiateBalanceTransferRequest,
    initiateBalanceTransferForRecurringAccount,
    registerCashOut,
    postUpdateDocumentUploadIdForCashOut,
    getSavedBankAccounts,
    registerNewBankAccountUsingBankStatementUpload,
    sendUserFeedbackEmail,
    createTrustPilotUniqueLink,
    saveReview,
    createAutoRefinanceApplication,
    initiateHomeReAttach,
    getHomeReAttachOffer,
    getQuickPromotionInfo,
    getPointOfferInfo,
    acceptHcmRemediationOffer,
    declineHcmRemediationOffer,
    addDqReason,
    acceptTermsOfService,
    getBreakupPaymentOptions,
    selectBreakupPaymentOption,
    dismissMegaphone,
    setExcessPaymentAllocationMethod,
    getAuthorizedCardholders,
    postUpdateAuthorizedCardholderStatus,
    requestFullCreditLimit,
    createMortgageRefinanceRequest,
    registerMortgagePaymentForRewards,
    getAvailaleSpotsForMortgageCashbackV2,
    getAprReductionOffer,
    acceptOrDeclineAprReductionOffer,
    getOrCreateUniformResidentialLoanApplication,
    submitUniformResidentialLoanApplicationMaritalStatus,
    submitUniformResidentialLoanApplicationAddressHistory,
    submitUniformResidentialLoanApplicationDependents,
    submitUniformResidentialLoanApplicationProperties,
    submitUniformResidentialLoanApplicationCurrentEmployment,
    submitUniformResidentialLoanApplicationCurrentIncome,
    submitUniformResidentialLoanApplicationEmploymentHistory,
    submitUniformResidentialLoanApplicationAdditionalIncome,
    submitUniformResidentialLoanApplicationBankAccounts,
    submitUniformResidentialLoanApplicationInvestmentAccounts,
    submitUniformResidentialLoanApplicationOtherAssets,
    submitUniformResidentialLoanApplicationLiabilities,
    submitUniformResidentialLoanApplicationDeclarationQuestions,
    submitUniformResidentialLoanApplicationHmda,
    createNewLoanApplicationForMortgageRefi,
    getMortgageRefinanceRates,
    getMortgageRefinanceDocuSignUrl,
    getMortgageBalance,
    getMaritalStatus,
    submitUniformResidentialLoanApplicationBrokerageAgreement,
    acceptToBeAmbassador,
    declineToBeAmbassador,
    getPaymentBreakdownByPlan,
    getAddressHistoryFromUserInput,
    getAddressHistoryFromReports,
    getDependentsFromUserInput,
    getSubjectPropertyFromReports,
    getPropertiesFromUserInput,
    getPropertiesFromReports,
    getCurrentEmploymentFromUserInput,
    getCurrentEmploymentFromReports,
    getCurrentIncomeFromUserInput,
    getCurrentIncomeFromReports,
    getPastEmploymentsFromUserInput,
    getOtherIncomesFromUserInput,
    getOtherAssetsFromUserInput,
    getLiabilitiesFromUserInput,
    getDeclarationQuestionsFromUserInput,
}
